<template>
  <div class="row">
    <div class="col-10">
      <span
        style="padding: 20px"
        v-if="!editMode"
      >{{ libelle }} [{{ order }}]</span>
      <div
        class="row"
        v-else
      >
        <div class="col-10">
          <input
            type="text"
            name="libelle"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="col-2">
          <input
            type="number"
            name="order"
            class="form-control"
            v-model="order"
          >
        </div>
      </div>
    </div>
    <div class="col-2 actions">
      <a
        href="javascript:void(0)"
        class="btn btn-sm btn-outline-primary"
        v-if="!editMode"
        @click.prevent="editMode = true"
      > <i class="icofont icofont-pencil" /> </a>
      <a
        href="javascript:void(0)"
        class="btn btn-sm btn-outline-success"
        v-if="editMode"
        @click.prevent="save"
      > <i class="icofont icofont-save" /> </a>
      <a
        href="javascript:void(0)"
        class="btn btn-sm btn-outline-dark"
        v-if="editMode"
        @click.prevent="editMode = false"
      > <i class="icofont icofont-close" /> </a>
        
      <a
        href="javascript:void(0)"
        class="btn btn-sm btn-outline-third"
        v-if="!editMode"
        @click.prevent="deleting"
      > <i class="icofont icofont-close" /> </a>
    </div>
  </div>
</template>

<script>
import { ADD_RESPONSE, DELETE_RESPONSE, UPDATE_RESPONSE } from '../../../../graphql/sanitaire';
export default {
    props: {
        response: {type: Object, required: true},
        question: {type: Object, required: true},
        addMode: {type: Boolean, default: false}
    },
    mounted(){
        if(this.response !== null) {
            this.libelle = this.response.libelle
            this.order = this.response.ordre
        } 
    },
    data(){
        return {
            editMode: false,
            libelle: null,
            order: null
        }
    },
    methods: {
        save(){
            let data = {
                libelle: this.libelle,
                question: this.question.uid,
                ordre: parseInt(this.order)
            }
            console.log(this.response.uid)
            if(this.response.uid === null || this.response.uid === undefined){
                // adding response
                this.$apollo.mutate({
                    mutation: ADD_RESPONSE,
                    variables: {
                        "response": {
                            ...data
                        }
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Response ${this.libelle} add successfully`)
                    }
                }).then((r) => {
                    console.log(r)
                    this.editMode = false
                }).catch((error) => {
                    console.error(error)
                })
            }else{
                // Editing response
                this.$apollo.mutate({
                    mutation: UPDATE_RESPONSE,
                    variables: {
                        "response": {
                            ...data
                        },
                        "uid": this.response.uid
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Response ${this.libelle} add successfully`)
                    }
                }).then((r) => {
                    console.log(r)
                    this.editMode = false
                }).catch((error) => {
                    console.error(error)
                })
            }
        },
        deleting(){
            this.$apollo.mutate({
                    mutation: DELETE_RESPONSE,
                    variables: {
                        "uid": this.response.uid
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Response ${this.libelle} deleted successfully`)
                    }
                }).then((r) => {
                    console.log(r)
                    this.editMode = false
                }).catch((error) => {
                    console.error(error)
                })
        }
    },
    computed: {

    }

}
</script>

<style lang="scss" scoped>
.actions{
    a{
        margin: 5px;
    }
}
</style>